@font-face {
  font-family: 'Elegant';
  src: url('./fonts/Elegant.ttf');
}

@font-face {
  font-family: 'JosefinSans';
  src: url('./fonts/JosefinSans.ttf');
}

div {
  font-family: 'JosefinSans', sans-serif;
  font-weight: 350 !important;
}

h1 {
  font-family: 'Elegant', sans-serif;
  font-size: 10vw !important;
}

.date {
  font-family: 'JosefinSans', sans-serif;
  font-weight: 100 !important;
  font-size: 5vw !important;
}

.Header {
  text-align: center;
  opacity: 0.5;
  transform: translateY(-3vh);
  transition: opacity 1s ease, transform 1s ease;
}

.Header.show {
  opacity: 1;
  transform: translateY(0);
}

.Header-header {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 300px;
}

.Header-header.finished {
  min-height: 15vh;
  transition: 1s;
}

.carole-franck {
  animation: typing 3s forwards;
  overflow: hidden;
  white-space: nowrap;
}

.nav-tabs {
  display: flex;
  justify-content: center;
  border-color: white !important;
  margin-bottom: 3vh !important;
}

.nav-tabs .nav-item {
  margin-left: 1vw;
}

.nav-tabs .nav-item .nav-link {
  border-radius: 0; /* Enlève le bord arrondi */
  border-color: #D8E9D9 !important; /* Enlève la bordure */
  color: black;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.nav-tabs .nav-item .nav-link.active {
  background-color: #D8E9D9;
}

.content {
  animation: fadeIn 1s ease-in-out;
}

.thanks-text-img {
  font-family: 'Elegant', sans-serif;
  font-size: 10vw !important;
  position: absolute;
  right:15vw;
  color: white;
  animation: fadeIn 1s ease-in-out;
}

.selected {
  filter: brightness(70%); /* Vous pouvez ajuster le niveau d'assombrissement ici */
  transition: filter 0.3s ease-in-out;
}

.selected-icon {
  position: absolute;
  top: 2vh;
  left: 10vw;
  color: #D8E9D9;
  font-size: 10vw;
  transition: color 0.3s ease-in-out;
}

.download-btn{
  margin-left: 1vw;
}

/* FadeIn animation */
@keyframes fadeIn {
  from {
    opacity: 0; /* Start with opacity 0 (invisible) */
  }
  to {
    opacity: 1; /* End with opacity 1 (fully visible) */
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}